<template>
  <div id="quiz">
    <div id="mapid"></div>
    <TitleHelper title="Geography Quiz"></TitleHelper>

    <div class="display" v-on:click="getQuestion">
      <span v-if="!quizStarted">
        Click to start
      </span>
      <span v-if="questionText && !questionAnswered">
        {{questionText}}
      </span>
      <span v-if="quizStarted && questionAnswered">
        {{outcome}} - Click to continue
      </span>

    </div>

    <table v-if="quizStarted && !questionAnswered" class="options">
    <tr>
      <td v-for="o in options" :key="o" v-on:click="attemptAnswer(o)">
        {{o}}
      </td>
    </tr>
    </table>


  </div>
</template>

<script>
/* eslint no-undef: 0 */
const axios = require("axios");
const config = require("../../assets/config");
import TitleHelper from '../../helpers/vueTitleHelper.vue';

export default {
  name: "Quiz",
  beforeCreate: function() {
    document.body.className = 'quiz';
  },
  data() {
    return {
      values: config,
      username: null,
      password: null,
      errorValue: null,
      map: null,
      question: null,
      questionText: null,
      correctAnswer: null,
      options: [],
      quizStarted: false,
      questionAnswered: false,
      outcome: null,
      countryName: null,
      countryObject: null,
      countryPolygon: null,
    };
  },
  mounted() {
    let mapboxScript = document.createElement('script');
    mapboxScript.setAttribute('src', 'https://api.mapbox.com/mapbox.js/v3.3.0/mapbox.js');
    document.head.appendChild(mapboxScript);
    mapboxScript = document.createElement('link');
    mapboxScript.setAttribute('href', 'https://api.mapbox.com/mapbox.js/v3.3.0/mapbox.css');
    mapboxScript.setAttribute('rel', 'stylesheet');
    document.head.appendChild(mapboxScript);
    window.addEventListener('load', () => {
      this.initMap();
    })
  },
  beforeDestroy() {
    window.removeEventListener('load', () => {
      this.initMap();
    }); 
  },
  components: {
    TitleHelper,
  },
  methods: {
    initMap() {
      L.mapbox.accessToken = 'pk.eyJ1IjoibXlrcmVldmUiLCJhIjoiY2s4MGU2dDZlMGRvYTNscGdvaWU3YmVuNSJ9.c6cEIN7FW7cZKscTMSu5CQ';
      this.map = L.mapbox.map('mapid').setView([38.63, -90.23], 5);
      L.mapbox.styleLayer('mapbox://styles/mykreeve/ck84a0c3e0qiz1il5fkpmxnm8').addTo(this.map);
    },
    attemptAnswer: function(o) {
      this.questionAnswered = true;
      if (o == this.correctAnswer) {
        this.outcome = "CORRECT";
      }
      else {
        this.outcome = "WRONG (" + this.correctAnswer + ")";
      }

      
      var greenIcon = new L.Icon({
        iconUrl:'../../../icons/marker-icon-green.png',
        shadowUrl:'../../../icons/marker-shadow.png',
        iconSize: [25,41],
        iconAnchor: [12,41],
        popupAnchor: [1,-34],
        shadowSize: [41,41]
      })
      var redIcon = new L.Icon({
        iconUrl:'../../../icons/marker-icon-red.png',
        shadowUrl:'../../../icons/marker-shadow.png',
        iconSize: [25,41],
        iconAnchor: [12,41],
        popupAnchor: [1,-34],
        shadowSize: [41,41]
      })

      this.answerMarker = L.marker([this.question.answer.latitude, this.question.answer.longitude], {icon: greenIcon}).addTo(this.map);
      this.answerMarker.bindTooltip(this.question.answer.name, {permanent: true, className: 'correct-tooltip'}).openTooltip();
      
      var w = this.question.wrong[0]
      var w1 = this.question.wrong[1]
      var w2 = this.question.wrong[2]
      this.wrongMarker = L.marker([w.latitude, w.longitude], {icon: redIcon}).addTo(this.map);
      this.wrongMarker.bindTooltip(w.name, {permanent: true, className: 'wrong-tooltip'}).openTooltip();
      this.wrongMarker1 = L.marker([w1.latitude, w1.longitude], {icon: redIcon}).addTo(this.map);
      this.wrongMarker1.bindTooltip(w1.name, {permanent: true, className: 'wrong-tooltip'}).openTooltip();
      this.wrongMarker2 = L.marker([w2.latitude, w2.longitude], {icon: redIcon}).addTo(this.map);
      this.wrongMarker2.bindTooltip(w2.name, {permanent: true, className: 'wrong-tooltip'}).openTooltip();
      
      var minlat = Math.min(this.question.answer.latitude, w.latitude, w1.latitude, w2.latitude);
      var maxlat = Math.max(this.question.answer.latitude, w.latitude, w1.latitude, w2.latitude);
      var minlong = Math.min(this.question.answer.longitude, w.longitude, w1.longitude, w2.longitude);
      var maxlong = Math.max(this.question.answer.longitude, w.longitude, w1.longitude, w2.longitude);
      
      var latbuffer = (maxlat - minlat)/15
      var longbuffer = (maxlong - minlong)/15

      var min = L.latLng(minlat-(latbuffer*3), minlong-longbuffer);
      var max = L.latLng(maxlat+latbuffer, maxlong+longbuffer);
      var bounds = L.latLngBounds(min, max);

      this.map.flyToBounds(bounds);

    },
    getQuestion: function() {
      if (this.questionAnswered || !this.quizStarted){
        if (this.countryPolygon) { 
          this.countryPolygon.remove();
        }
        axios
          .get(this.values.BACKEND_CONNECTION + "://"+ this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/quiz/capital")
          .then(response => {
            this.question = response.data;
            this.correctAnswer = this.question.answer.name;
            this.countryName = this.question.country;
            var answers = [this.question.answer.name, this.question.wrong[0].name, this.question.wrong[1].name, this.question.wrong[2].name]
            this.options = [];

            if (this.answerMarker) {
              this.map.removeLayer(this.answerMarker);
            }

            if (this.wrongMarker) {
              this.map.removeLayer(this.wrongMarker);
              this.map.removeLayer(this.wrongMarker1);
              this.map.removeLayer(this.wrongMarker2);
            }

            while (answers.length > 0) {
              var item = Math.floor(Math.random() * answers.length);
              this.options.push(answers.splice(item, 1)[0]);
            }

            this.questionText = this.question.question;
            this.quizStarted = true;
            this.questionAnswered = false;
            axios
              .get(this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/country/"+this.countryName)
              .then(response => {
                this.countryObject = response.data;
                this.countryPolygon = L.polygon(this.countryObject, {color: 'yellow'}).addTo(this.map);
                this.map.flyToBounds(this.countryPolygon.getBounds());
              })
              .catch((error) => {
                this.errorValue = error;
              })
          })
          .catch((error) => {
                this.errorValue = error;
          });
      }
    },
    attemptLogin: function() {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var body = { username: this.username, password: this.password };
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/login/";
      axios
        .post(url, body, config)
        .then(response => {
          this.$store.state.jwt = response["data"];
        })
        .catch(error => {
          this.errorValue = error;
        });
    }
  },
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.spacer {
  height:30px;
}
div.error {
  margin: 20px;
  padding: 10px;
  background-color: #ff9999;
  border: 2px red;
}
#mapid { height: calc(100vh); }

.display {
  position: relative;
  bottom: 120px;
  background: white;
  margin: auto;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  display: block;
  width: 80%;
}

table.options {
  position: absolute;
  bottom: 40px;
  width: 100%;
  border-spacing: 15px 0;
}

table.options td {
  width: 25%;
  background: white;
  border-radius: 5px;
  border: 1px solid black;
}

table.options td:hover {
  background: rgb(11, 189, 189);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
}


@keyframes think {
  0% {
    transform: scale(0.95);
  }
  8% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.fade-transition {
  transition: opacity 2s ease;
}

.fade-enter, .fade-leave {
  opacity: 0;
}

div.user {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: small;
  text-align: right;
}

span.blue {
  color: teal;
  cursor: pointer;
}

#quiz {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


button {
  background-color: teal;
  color: white;
  border: 0px;
  width: 25%;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
}

button[disabled] {
  background-color: #999999;
}

button[disabled]:hover {
  background-color: inherit;
  box-shadow: inherit;
}

button:hover {
  background-color: #2bb7c4;
  box-shadow: 3px 3px 2px 0px #cccccc;
}

.tab-button {
  border-radius: 3px 3px 0px 0px;
  padding: 6px 10px;
  cursor: pointer;
  background: #f0f0f0;
  color: black;
  margin: 0px 4px 0px 4px;
  width: 15%;
}

.tab-button:hover {
  background: #e0e0e0;
  box-shadow: none;
}
.tab-button.active {
  background: teal;
  color: white;
  box-shadow: none;
}

.tab {
  border-top: 1px solid #cccccc;
  height: calc(100vh - 91px);
}


</style>
<style>
.correct-tooltip {
  background: green;
  border: 1px solid #193a19;
}
.leaflet-tooltip-left.correct-tooltip:before {
  border-left-color: green;
}
.leaflet-tooltip-right.correct-tooltip:before {
  border-right-color: green;
}
.leaflet-tooltip-top.correct-tooltip:before {
  border-top-color: green;
}
.leaflet-tooltip-bottom.correct-tooltip:before {
  border-bottom-color: green;
}
.wrong-tooltip {
  background: red;
  border: 1px solid #770000;
}
.leaflet-tooltip-left.wrong-tooltip:before {
  border-left-color: red;
}
.leaflet-tooltip-right.wrong-tooltip:before {
  border-right-color: red;
}
.leaflet-tooltip-top.wrong-tooltip:before {
  border-top-color: red;
}
.leaflet-tooltip-bottom.wrong-tooltip:before {
  border-bottom-color: red;
}

</style>
